




































































































































import { Vue, Component, Ref } from 'vue-property-decorator'
import SeletorDeNcm from '@/components/ui/SeletorDeNcm.vue'
import SeletorDeTag from '@/components/produto/SeletorDeTag.vue'
import SeletorDeMarca from '@/components/produto/SeletorDeMarca.vue'
import SeletorDeRegimeFiscal from '@/components/fiscal/SeletorDeRegimeFiscal.vue'
import { obterFiltroDeBuscaDeProdutosDefault } from '@/shareds/produto-shareds'
import { nextTick } from '@/shareds/utils'
import { ImportaItensUseCase } from '@/usecases'
import { FiltroDeBuscaDeProdutos } from '@/models'
import SeletorDeCategoria from '@/views/application/produtos/EdicaoDeProduto/SeletorDeCategoria.vue'

@Component({
	components: {
		SeletorDeNcm,
		SeletorDeTag,
		SeletorDeMarca,
		SeletorDeRegimeFiscal,
		SeletorDeCategoria,
	},
})
export default class MenuDeFiltrosDeProduto extends Vue {
	@Ref() tags!: HTMLInputElement
	@Ref() uploadDeSkus!: HTMLInputElement

	importaItensUseCase =  new ImportaItensUseCase()
	temCsvSelecionado = false
	mostra: boolean | null = null

	filtro = obterFiltroDeBuscaDeProdutosDefault()


	async filtrar() {
		await nextTick()
		const skusNormalizados = this.filtro.skus?.map(sku => sku.toString().trim())

		this.filtro = {
			...this.filtro,
			skus: skusNormalizados,
		}
		const filtrosNormalizados: FiltroDeBuscaDeProdutos = {
			tags: this.filtro.tags.length ? this.filtro.tags : undefined,
			ncms: this.filtro.ncms.length ? this.filtro.ncms : undefined,
			regimes: this.filtro.regimes.length ? this.filtro.regimes : undefined,
			marcas: this.filtro.marcas.length ? this.filtro.marcas : undefined,
			skus: this.filtro.skus.length ? this.filtro.skus : undefined,
			eans: this.filtro.eans.length ? this.filtro.eans : undefined,
			categorias: this.filtro.categorias && this.filtro.categorias.length
				? this.filtro.categorias
				: undefined,
		}
		this.$emit('confirmar', filtrosNormalizados)
		this.mostra = false
	}

	subirSkus(event: Event) {
		if (!event.target) return
		const fileInput = event.target as HTMLInputElement
		if (!fileInput.files || !fileInput.files.length) return
		const reader = new FileReader();

		this.temCsvSelecionado = true

		reader.onload = (evt) => {
			if (!evt.target) return
			if (evt.target.readyState != 2) return;
			if (evt.target.error) {
				throw('Error while reading file');
			}
			if (!evt.target.result) return

			this.filtro.skus = (evt.target.result as string)
				.split('\n')
				.map(sku => sku.split(';')[0].trim().replace(/"/g, ''))
				.filter(sku => !!sku)

			this.uploadDeSkus.value = ''
		}
		reader.readAsText(fileInput.files[0]);

	}

	normalizarValores(skus: string[]) {
		const valoresFormatados = skus
			.flatMap(skus => skus
				.replace(/\s+/g, ' ')
				.split(' ')
				.filter(sku => !!sku),
			)
		this.filtro.skus = valoresFormatados
	}

	obterDefault(){
		const limpa = {
			ncms: this.filtro.ncms = [],
			tags: this.filtro.tags = [],
			marcas: this.filtro.marcas = [],
			skus: this.filtro.skus = [],
			regimes: this.filtro.regimes = [],
			categorias: this.filtro.categorias = [],
		}
		return limpa
	}
}
