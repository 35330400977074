


















































import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'
import { ProdutoProjection } from '@/models'
import CardDeProduto from '@/components/produto/CardDeProduto.vue'
import { ordenarProdutoPorNome, ordenarProdutosAgrupadasPorCategoria } from '@/store/vuex/produto/ProdutoStore'

@Component({
	components: {
		CardDeProduto,
	},
})
export default class ListagemDeProdutosPorCards extends Vue  {
	@PropSync('value') ativo!: ProdutoProjection | ProdutoProjection[] | null
	@PropSync('selecionados', {
		type: Array,
		default: () => [],
	}) selecionadosSync!: string[]
	@Prop({ type: Array, default: () => [] }) produtos!: ProdutoProjection[]
	@Prop({ type: Function }) to?: (produto: ProdutoProjection) => void
	@Prop({ type: Boolean }) encurtado!: boolean

	get produtosPorCategoria() {
		const mapProdutosPorEstado: {
			[categoria: string]: ProdutoProjection[]
		} = this.produtos.reduce(
			(mapProdutosPorEstado, produto) => ({
				...mapProdutosPorEstado,
				[produto.categoriaNome || '']: [
					...(mapProdutosPorEstado[produto.categoriaNome || ''] || []),
					produto,
				],
			}),
			{},
		)
		const produtosPorEstados = Object.entries(mapProdutosPorEstado)
			.map(([categoria, produtos]) => ({
				categoria,
				produtos: produtos.sort(ordenarProdutoPorNome),
			}))
			.sort(ordenarProdutosAgrupadasPorCategoria)
		return produtosPorEstados
	}
}

