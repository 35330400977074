


























































import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'
import { ProdutoProjection } from '@/models'
import { Fragment } from 'vue-fragment'
import { VListItemContent } from 'vuetify/lib'
import CheckboxButton from '@/components/ui/CheckboxButton.vue'

@Component({
	components: {
		Fragment,
		VListItemContent,
		CheckboxButton,
	},
})
export default class ListagemDeProdutosPorLista extends Vue  {
	@PropSync('value') selecionado!: ProdutoProjection | ProdutoProjection[] | null
	@PropSync('selecionados', {
		type: Array,
		default: () => [],
	}) selecionadosSync!: string[]
	@Prop({ type: Array, default: () => [] }) produtos!: ProdutoProjection[]
	@Prop({ type: Function }) to?: (produto: ProdutoProjection) => void
	@Prop() encurtado!: boolean
}

