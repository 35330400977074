var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TelaGenerica',{attrs:{"mensagemAdicionar":"Adicionar Produto","rotaPrincipal":"Produtos","recurso":"produtos","childProps":{
		selecionados: !_vm.usarTodosOsFiltrados
			? _vm.selecionados
			: _vm.filtroAplicado, 
			usarTodosOsFiltrados: _vm.usarTodosOsFiltrados
	}},on:{"adicionar":function () { return _vm.$router.push({ name: 'Novo Produto' }); }},scopedSlots:_vm._u([{key:"busca",fn:function(){return [_c('div',{staticClass:"d-flex align-end"},[_c('MenuDeFiltrosDeProduto',{attrs:{"offset-y":""},on:{"confirmar":_vm.filtrar},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
	var on = ref.on;
return [_c('v-text-field',_vm._g({key:"field",ref:"pesquisa",attrs:{"data-cy":"pesquisar-produto","placeholder":"Pesquisar...","hide-details":"","type":"search","clearable":"","single-line":"","append-outer-icon":"mdi-filter-menu"},on:{"click:append-outer":on.click},model:{value:(_vm.busca),callback:function ($$v) {_vm.busca=$$v},expression:"busca"}},_vm.$listeners))]}}])}),_c('v-btn',{staticClass:"ml-2",attrs:{"data-cy":"botao-aplicar-filtro","color":"primary"},on:{"click":_vm.setBusca}},[_vm._v(" Buscar ")])],1)]},proxy:true},{key:"filtros",fn:function(){return [_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('div',{staticClass:"ml-auto"},[_c('v-btn-toggle',{attrs:{"mandatory":""},model:{value:(_vm.tipoDaListagem),callback:function ($$v) {_vm.tipoDaListagem=$$v},expression:"tipoDaListagem"}},[_c('v-btn',{attrs:{"value":"grade"}},[_c('v-icon',[_vm._v("mdi-view-grid")])],1),_c('v-btn',{attrs:{"value":"lista"}},[_c('v-icon',[_vm._v("mdi-format-align-left")])],1)],1)],1),(_vm.selecionados.length)?_c('div',[_c('v-btn',{attrs:{"small":"","disabled":""}},[_vm._v(" Edição máx. 1600 ")])],1):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex flex-row-reverse align-center",staticStyle:{"height":"48px"}},[_c('v-slide-x-transition',[(_vm.selecionados.length)?_c('div',[_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function () { return _vm.$router.push({
									name: 'Editar Produtos',
								}); }}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)],1):_vm._e()]),(_vm.produtosOrdenados.length && !_vm.carregando )?_c('CheckboxButton',{attrs:{"value":!!_vm.selecionados.length && _vm.selecionados.length === _vm.produtosOrdenados.length,"indeterminate":!!_vm.selecionados.length && _vm.selecionados.length !== _vm.produtosOrdenados.length},on:{"input":function () { return _vm.selecionados.length || _vm.selecionados.length === _vm.produtosOrdenados.length
							? _vm.limparSelecao()
							: _vm.selecionarTodos(); }}}):_vm._e()],1),(_vm.pagina)?_c('div',{staticClass:"d-flex align-center ml-auto",staticStyle:{"height":"48px"}},[_c('span',[_vm._v(" "+_vm._s(1 + _vm.pagina.number * _vm.pagina.size)+" – "+_vm._s(_vm.pagina.numberOfElements + _vm.pagina.number * _vm.pagina.size)+" de "+_vm._s(_vm.pagina.totalElements)+" ")]),_c('v-btn',{attrs:{"icon":"","disabled":_vm.pagina.first || _vm.carregando},on:{"click":function () { return _vm.pagina && _vm.filtrar(_vm.filtroAplicado, { page: _vm.pagina.number - 1 }); }}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{attrs:{"icon":"","disabled":_vm.pagina.last || _vm.carregando},on:{"click":function () { return _vm.pagina && _vm.filtrar(_vm.filtroAplicado, { page: _vm.pagina.number + 1 }); }}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1):_vm._e()]),_c('div',[(_vm.todosForamSelecionados)?_c('v-alert',{attrs:{"type":"info","text":""}},[(!_vm.usarTodosOsFiltrados)?[_vm._v(" Todos os "+_vm._s(_vm.selecionados.length)+" produtos dessa página estão selecionados. "),_c('a',{staticClass:"text-decoration-underline",on:{"click":function($event){$event.preventDefault();_vm.usarTodosOsFiltrados = true}}},[_vm._v("Selecionar todos os produtos que correspondem a esta pesquisa.")])]:[_vm._v(" Todos os "+_vm._s(_vm.pagina && _vm.pagina.totalElements)+" produtos da pesquisa estão selecionados. "),_c('a',{staticClass:"text-decoration-underline",on:{"click":function($event){$event.preventDefault();return _vm.limparSelecao.apply(null, arguments)}}},[_vm._v("Limpar Seleção")])]],2):_vm._e()],1)])]},proxy:true},{key:"listagem",fn:function(){return [(_vm.carregando)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_c(_vm.componenteDaListagem,{tag:"component",staticClass:"overflow-hidden",attrs:{"produtos":_vm.produtosOrdenados,"encurtado":_vm.abaLateral,"to":function (produto) { return ({
				name: 'Produto',
				params: { id: produto.id }
			}); },"color":"primary","selecionados":_vm.selecionados},on:{"update:selecionados":function($event){_vm.selecionados=$event}}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }